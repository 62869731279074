import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { USER_ROLES_ENUM } from "config/constants";
import ROUTES from "config/routes";
import { useAuthContext } from "contexts";
import { useURLSearchParams, useUpdateAssignedNotifications } from "hooks";
import moment from "moment";
import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { DeleteIcon } from "resources/icons";
import {
  NOTIFICATION_ACTION_TYPE,
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
  NotificationAssignment,
  NotificationMessage,
} from "types/notifications";

const STATUS_CHANGE: Record<NOTIFICATION_STATUS, NOTIFICATION_STATUS> = {
  [NOTIFICATION_STATUS.UNREAD]: NOTIFICATION_STATUS.READ,
  [NOTIFICATION_STATUS.READ]: NOTIFICATION_STATUS.DELETED,
  [NOTIFICATION_STATUS.DELETED]: NOTIFICATION_STATUS.DELETED,
};

type NotificationItemProps = {
  notification: NotificationAssignment;
  onItemClick: () => void;
};

export const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  onItemClick,
}) => {
  const {
    id,
    message: { title, text, type },
    status,
  } = notification;
  const isStatusUnread = status === NOTIFICATION_STATUS.UNREAD;
  const isWarningType = type === NOTIFICATION_TYPE.WARNING;

  const { updateAssignedNotifications } = useUpdateAssignedNotifications();

  const updateNotificationStatus = useCallback(async () => {
    await updateAssignedNotifications({
      assignedNotifications: [{ id }],
      status: STATUS_CHANGE[status],
    });
  }, [id, status, updateAssignedNotifications]);

  const onIconClick = () => updateNotificationStatus();
  const onBtnClick = () => updateNotificationStatus();
  const onLinkClick = useCallback(() => {
    if (isStatusUnread) {
      updateNotificationStatus();
    }
    onItemClick();
  }, [isStatusUnread, onItemClick, updateNotificationStatus]);

  return (
    <ListItem
      className={`group/item px-0 py-0`}
      secondaryAction={
        <IconButton
          onClick={onIconClick}
          edge="end"
          aria-label={isStatusUnread ? "mark as read" : "delete"}
          className="invisible group-hover/item:visible"
        >
          {isStatusUnread ? <MarkEmailReadOutlinedIcon /> : <DeleteIcon />}
        </IconButton>
      }
    >
      <NotificationItemLink
        onLinkClick={onLinkClick}
        onBtnClick={isStatusUnread ? onBtnClick : undefined}
        notificationMessage={notification.message}
      >
        <ListItemAvatar>
          <Avatar
            className={`h-10 w-10 ${
              isStatusUnread
                ? "bg-purple-4 text-white"
                : "bg-white text-purple-4"
            } ${isWarningType ? "bg-red-2" : ""}`}
          >
            {isWarningType ? (
              <NotificationImportantIcon />
            ) : (
              <AnnouncementOutlinedIcon />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={title || " "} secondary={text || " "} />
      </NotificationItemLink>
    </ListItem>
  );
};

const generateLink = (
  actionParams: string,
  searchParams: URLSearchParams,
  userRole?: USER_ROLES_ENUM
) => {
  const role = userRole ?? USER_ROLES_ENUM.EMPLOYEE;
  const routes = ROUTES[role];
  const params: Record<string, string> = JSON.parse(actionParams);

  switch (params.module) {
    case "timesheets": {
      if (params.employee_id) {
        searchParams.set("employeeId", params.employee_id);
      }
      searchParams.set(
        "startDate",
        moment(params.start_date).startOf("week").toISOString()
      );
      return routes.timesheetsDetails + `?${searchParams}`;
    }
    // TODO
    // case "manager_tasks": {
    //   searchParams.set("taskId", params.task_id);
    //   return routes.managerTaskDetails + `?${searchParams}`;
    // }
  }
};

type NotificationItemLinkProps = {
  onBtnClick: (() => void) | undefined;
  onLinkClick: () => void;
  notificationMessage: NotificationMessage;
  children: React.ReactNode;
};

const NotificationItemLink: FC<NotificationItemLinkProps> = ({
  notificationMessage: { actionParams, actionType },
  onLinkClick,
  onBtnClick,
  children,
}) => {
  const { user } = useAuthContext();
  const { searchParams } = useURLSearchParams();

  const isRedirectType = actionType === NOTIFICATION_ACTION_TYPE.REDIRECT;
  const link = actionParams
    ? generateLink(actionParams, searchParams, user?.role)
    : undefined;

  const hasLink = !!(isRedirectType && link);
  return hasLink ? (
    <Link
      className="flex w-full items-center px-2 py-2"
      to={link}
      onClick={onLinkClick}
    >
      {children}
    </Link>
  ) : (
    <div
      role={onBtnClick ? "button" : undefined}
      onClick={onBtnClick ? onBtnClick : undefined}
      className="flex w-full items-center py-2 pl-2 pr-12"
    >
      {children}
    </div>
  );
};
