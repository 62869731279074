import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { RawTicket } from "types/tickets";

type GetTicketsData = {
  tickets: RawTicket[];
};

type HookType = QueryResult<GetTicketsData> & {
  data: GetTicketsData;
};

export const GET_TICKETS = gql`
  query getTickets {
    tickets: manager_tasks_tasks {
      id
      type
      status
      assignedToId: assigned_to
      createdById: created_by
      title
      description
      details
    }
  }
`;

export const useTickets = (
  options?: QueryHookOptions<GetTicketsData>
): HookType => {
  const { data, ...rest } = useQuery<GetTicketsData>(GET_TICKETS, options);

  const parsedData: GetTicketsData = {
    tickets: data?.tickets ?? [],
  };

  return { data: parsedData, ...rest };
};
